/***************** Header and Nav *************************/
header
{
	margin-bottom: 114px;
}
#top-nav
{
	background-color: #fff;
	/*height: 5.375rem; had padding on top and bottom of 16rem */
	padding-top: 0.625rem;
	padding-bottom: 0.5rem;
}
#top-nav .lc-ico {
	display: inline-block;
	height: 2rem;
	width: 2rem;
}
.main-nav {
  background-color: #06486a;
  width: 100%;
  z-index: 30;
  height: 45px;
}

.main-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  /* background-color: $dark; */
  background-color: #06486a;
}

.main-nav .menu li a {
  display: block;
  padding: 0.625rem;
  text-decoration: none;
  color: #fff;
}

.main-nav .menu li a:hover,
.main-nav .menu-btn:hover ,
.main-nav .menu li a:active,
.main-nav .menu-btn:active,
.main-nav .menu li a:focus,
.main-nav .menu-btn:focus 
{
  background-color: #053a55;
}

.main-nav .menu, .main-nav .menu-search {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

.main-nav .menu-icon, .main-nav .menu-search-icon {
  cursor: pointer;
  float: right;
  padding: 1.25rem;
  position: relative;
  user-select: none;
}
.main-nav .menu-icon
{
	padding: 1.25rem 0.75rem 1.125rem 1.75rem;
}

.main-nav .menu-search-icon {
	float: left;
	line-height: 1;
}

.main-nav .menu-search-icon {
	padding: 0rem 0.625rem 0rem 0rem;
}

.main-nav .menu-search-icon i
{
	font-size: 1.125rem;

}

.main-nav .menu-icon .navicon {
  background: #fff;
  display: block;
  height: 1px;
  position: relative;
  transition: background .2s ease-out;
  width: 1.75rem;
}

.main-nav .menu-search-icon
{
	color: #fff;
}
.menu-search-btn-container
{
	/*background-color: $success;*/
	background-color: #548a3e;
	padding: 0.8125rem;
	padding-top: 0.875rem;
	padding-bottom: 0.75rem;
}

.main-nav .menu-icon .navicon:before,
.main-nav .menu-icon .navicon:after{
  background: #fff;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.main-nav .menu-icon .navicon:before {
  top: 0.625rem;
}

.main-nav .menu-icon .navicon:after {
  top: -0.625rem;
}

.main-nav .menu-btn, .main-nav .menu-search-btn {
  display: none;
}

.main-nav .menu-btn:checked ~ .menu, .main-nav .menu-search-btn:checked ~ .menu-search {
  /*max-height: 38rem;*/
  overflow-y: scroll;
  max-height: var(--bs-scroll-height,80vh);
}

.full-page .main-nav .menu-btn:checked ~ .menu,
.full-page .main-nav .menu-search-btn:checked ~ .menu-search
{
	max-height: calc(100vh - 200px);
	padding-bottom: 2rem;
}

.main-nav .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.main-nav .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.main-nav .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.main-nav .menu-btn:checked ~ .menu-icon .navicon:before,
.main-nav .menu-btn:checked ~ .menu-icon .navicon:after {
  top: 0;
}

.main-nav .menu-search > li > div.container-fluid
{
	max-width: 1400px;
}
.main-nav .menu-search li:first-child, .main-nav .menu li:last-child
{
	padding-bottom: 2rem;
	border-bottom: 1px solid #0073ae;
}
.dropdown-toggle-checkbox
{
	display: none;
}
.dropdown-toggle-checkbox-label
{
	color: #fff;
	cursor: pointer;
	user-select: none;
	display: block;
	padding: 0.625rem;
	text-decoration: none;
	color: #fff;
}
.dropdown-toggle-checkbox-label:hover,
.dropdown-toggle-checkbox-label:active,
.dropdown-toggle-checkbox-label:focus,
.dropdown-toggle-checkbox:checked ~ .dropdown-toggle-checkbox-label
{
  background-color: #053a55;
}
.dropdown-toggle-checkbox-label::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid;
	border-right: .3em solid transparent;
	border-bottom: 0;
	border-left: .3em solid transparent;
	color: #fff;
}
.dropdown-toggle-checkbox:checked ~ .dropdown-menu
{
	display: block;
}

.find-make-model-form, .budget-form
{
	background-color: #fff;
	border-radius: 1rem;
}
.budget-search
{
	margin-right: auto;
	margin-left: auto;
	max-width: 320px;
}
.budget-form
{
	background-color: #fff;
	border-radius: 1rem;
}
.find-make-model
{
	max-width: 320px;
	margin-right: auto;
	margin-left: auto;
}
.find-make-model-header
{
	font-size: 1.125rem;
}
.find-make-model-form
{
	padding: 1.25rem 0.75rem 1.25rem 4rem;
	max-height: 5.75rem;
}
.budget-form
{
	padding: 0.75rem;
}
.find-make-model-form .btn, .budget-form .btn
{
	padding: 0.625rem;
}
#find-make-model-img
{
	position: absolute;
	left: -1.2rem;
	top: -2rem;
	width: 5rem;
	height: auto;
}
select#makelist, select#modellist, select#home-makelist, select#home-modellist, select#search-min-price, select#search-max-price, select#search-car-or-van-price
{
	border: 0px none;
	border-radius: 0;
	width: auto;
	/*color: $primary;*/
	color: #0073ae;
	font-size: 1.125rem;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 1rem; 
	padding-left: 0;
	/* background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='$primary' d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")); */
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%230073ae' d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
	background-position: right .25rem center;
}
select#search-min-price, select#search-max-price, select#search-car-or-van-price
{
	padding-right: 2.25rem;
}
select#makelist:focus, select#modellist:focus, select#search-min-price:focus, select#search-max-price:focus, select#search-car-or-van-price:focus, select#home-makelist:focus, select#home-modellist:focus
{
	box-shadow: none;
}
select#makelist, select#modellist, select#home-makelist, select#home-modellist
{
	width: 11rem;
}
div.make-active select#modellist, div.model-active select#makelist, div.make-active select#home-modellist, div.model-active select#home-makelist
{
	background-color: #fff;
	color: #6c6c6c;
	/*font-size: $font-size-base;*/
	font-size: 0.875rem;
	font-family: $alt-font;
	font-family: arial;
	/* background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='#6c6c6c' d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")); */
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%236c6c6c' d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
}
.nav-tabs {
	border-bottom: $nav-tabs-border-width solid $body-bg;

  .nav-link.active,
  .nav-item.show .nav-link {
    background-color: #fff;
		border-color: $body-bg;
		color: $body-bg;
  }
}

@media screen and (max-width: 359px)
{
	.find-make-model-form
	{
		padding-left: 1.125rem;
	}
	.find-make-model-form div:nth-child(2)
	{
		padding-left: 1.475rem !important;
	}
	#find-make-model-img
	{
		display: none;
	}
}

@media screen and (max-width: 767px) {
	select#makelist, select#modellist, select#home-makelist, select#home-modellist, select#search-min-price, select#search-max-price, select#search-car-or-van-price {
		border: 1px solid #ced4da;
		border-radius: 1rem;
		padding-right: 1.25rem; 
		padding-left: 0.5rem;
	}
	select#makelist, select#home-makelist, select#home-modellist {
		margin-bottom: 0.25rem;
	}
	select#makelist:focus, select#modellist:focus, select#search-min-price:focus, select#search-max-price:focus, select#search-car-or-van-price:focus, select#home-makelist:focus, select#home-modellist:focus {
		border-color: #ced4da;
	}
	select#makelist, select#modellist, select#home-makelist, select#home-modellist {
		width: 11.5rem;
	}
}

@media screen and (max-width: 991px) {
	.main-nav .menu li.dropdown ul.dropdown-menu.show, .dropdown-toggle-checkbox:checked ~ .dropdown-menu
	{
		position: static !important;
		transform: none !important;
	}
	.main-nav .menu li.dropdown ul.dropdown-menu.show, .dropdown-toggle-checkbox:checked ~ .dropdown-menu
	{
		border: 0px none;
		border-radius: 0px;
	}
	.main-nav .menu li.dropdown ul.dropdown-menu.show li, .dropdown-toggle-checkbox:checked ~ .dropdown-menu li
	{
		padding-left: 1.5rem;
	}
	.main-nav .menu li.dropdown ul.dropdown-menu.show li:last-child, .dropdown-toggle-checkbox:checked ~ .dropdown-menu li:last-child
	{
		padding-bottom: 0px;
		border-bottom: 0px none;
	}
	.main-nav .menu li.dropdown ul.dropdown-menu.show li a::before, .dropdown-toggle-checkbox:checked ~ .dropdown-menu li a::before
	{
		content: "\2014\a0\a0\a0";
	}
}

@media screen and (min-width: 576px)
{
	#find-make-model-img
	{
		left: -7rem;
		width: auto;
	}
	.find-make-model-form
	{
		padding-left: 2rem;
	}
}

@media screen and (min-width: 768px)
{
	header
	{
		margin-bottom: 120px;
	}
}

@media screen and (min-width: 992px) {
	body.has-image-behind-nav .main-nav {
	  background-color: transparent;
	}
	body.has-image-behind-nav .main-nav.override-has-image-behind-nav {
	  background-color: #06486a;
	}
	.menu-search-btn-container
	{
		border-bottom-left-radius: 0.375rem;
		border-bottom-right-radius: 0.375rem;
	}
	.main-nav .menu-search-icon
	{
		padding-left: calc((100vw - 900px) / 2);
	}
	.main-nav.site-3 .menu-search-icon
	{
		padding-left: calc((100vw - 630px) / 2);
	}
	.main-nav.site-4 #dropdown_link_leasing_guides
	{
		margin-left: 195px;
	}
	.main-nav.site-5 .menu-search-icon
	{
		padding-left: calc((100vw - 540px) / 2);
	}
	.main-nav li {
		float: left;
	}
	.dropdown-toggle-checkbox:checked ~ .dropdown-menu
	{
		margin-top: 2px;
		overflow-y: visible;
		max-height: none;
		min-width: 200px;
		padding-top: 0.675rem;
		padding-bottom: 0.675rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.main-nav li.dropdown ul.dropdown-menu.show li, .dropdown-toggle-checkbox:checked ~ .dropdown-menu li {
		float: none;
	}
	.main-nav li.dropdown ul.dropdown-menu
	{
		box-shadow: 0px 0px 3px 1px RGB(255, 255, 255, 0.5) inset;
	}
	.main-nav li.dropdown ul.dropdown-menu.dropdown-menu-makes-no-scroll.show, .dropdown-toggle-checkbox:checked ~ .dropdown-menu.dropdown-menu-makes-no-scroll
	{
		width: 963px;
		margin-left: -145px;
	}
	.main-nav li.dropdown ul.dropdown-menu.dropdown-menu-makes-no-scroll.dropdown-menu-makes-vans-no-scroll.show, .dropdown-toggle-checkbox:checked ~ .dropdown-menu.dropdown-menu-makes-no-scroll.dropdown-menu-makes-vans-no-scroll
	{
		width: 963px;
		margin-left: -214px;
	}
	.main-nav.site-5 li.dropdown ul.dropdown-menu.dropdown-menu-makes-no-scroll.show, .main-nav.site-5 .dropdown-toggle-checkbox:checked ~ .dropdown-menu.dropdown-menu-makes-no-scroll
	{
		margin-left: -325px;
	}
	.main-nav li.dropdown ul.dropdown-menu.dropdown-menu-leasing-guides-no-scroll.show, .dropdown-toggle-checkbox:checked ~ .dropdown-menu-leasing-guides-no-scroll
	{
		width: 778px;
	}
	.main-nav.site-4 li.dropdown ul.dropdown-menu.dropdown-menu-leasing-guides-no-scroll.show, .main-nav.site-4 .dropdown-toggle-checkbox:checked ~ .dropdown-menu-leasing-guides-no-scroll
	{
		margin-left: 91px;
	}
	.main-nav.site-5 li.dropdown ul.dropdown-menu.dropdown-menu-leasing-guides-no-scroll.show, .main-nav.site-5 .dropdown-toggle-checkbox:checked ~ .dropdown-menu-leasing-guides-no-scroll
	{
		margin-left: -405px;
	}
	.main-nav li.dropdown ul.dropdown-menu.dropdown-menu-deals-no-scroll.show, .dropdown-toggle-checkbox:checked ~ .dropdown-menu-deals-no-scroll
	{
		width: 960px;
		margin-left: -282px;
	}
	.main-nav.site-5 li.dropdown ul.dropdown-menu.dropdown-menu-deals-no-scroll.show, .main-nav.site-5 .dropdown-toggle-checkbox:checked ~ .dropdown-menu-deals-no-scroll
	{
		margin-left: -420px;
	}
	.main-nav li.dropdown ul.dropdown-menu.dropdown-menu-makes-no-scroll.show li, .dropdown-toggle-checkbox:checked ~ .dropdown-menu.dropdown-menu-makes-no-scroll li {
		float: left;
		width: 189px;
	}
	.main-nav li.dropdown ul.dropdown-menu.dropdown-menu-deals-no-scroll.show li, .dropdown-toggle-checkbox:checked ~ .dropdown-menu-deals-no-scroll li {
		float: left;
		width: 314px;
	}
	.main-nav li.dropdown ul.dropdown-menu.dropdown-menu-leasing-guides-no-scroll.show li, .dropdown-toggle-checkbox:checked ~ .dropdown-menu.dropdown-menu-leasing-guides-no-scroll li {
		float: left;
		width: 380px;
	}
	.main-nav li.dropdown ul.dropdown-menu.dropdown-menu-makes-no-scroll.show li  a, .main-nav li.dropdown ul.dropdown-menu.dropdown-menu-deals-no-scroll.show li  a,
	.main-nav li.dropdown ul.dropdown-menu.dropdown-menu-leasing-guides-no-scroll.show li  a,
	.dropdown-toggle-checkbox:checked ~ .dropdown-menu li a
	{
		padding-top: 5px;
		padding-bottom: 5px;
	}
	
	.main-nav .menu {
		clear: none;
		float: none;
		max-height: none;
		overflow: visible;
	}
	.main-nav .menu-icon {
		display: none;
	}
	.main-nav  .menu-search
	{
		background-image: url(/images/search-menu-car-bg.png);
		background-repeat: no-repeat;
	}
	.main-nav .menu-search li:first-child
	{
		padding-bottom: 4rem;
	}
	.main-nav .menu li:last-child
	{
		padding-bottom: 0;
		border-bottom: 0px none;
	}
	.find-make-model
	{
		margin-right: 2rem;
	}
	.find-make-model-header
	{
		font-size: 1.5rem;
	}
	.budget-search
	{
		margin-right: 2rem;
	}
}
@media screen and (min-width: 1200px) {
	header
	{
		margin-bottom: 124px;
	}
	#find-make-model-img
	{
		left: -12rem;
	}
	.main-nav.site-4 #dropdown_link_leasing_guides
	{
		margin-left: 285px;
	}
	.main-nav.site-4 li.dropdown ul.dropdown-menu.dropdown-menu-leasing-guides-no-scroll.show, .main-nav.site-4 .dropdown-toggle-checkbox:checked ~ .dropdown-menu-leasing-guides-no-scroll
	{
		margin-left: 181px;
	}
}
@media screen and (min-width: 1400px)
{
	.find-make-model
	{
		max-width: 420px;
	}
	.main-nav.site-4 #dropdown_link_leasing_guides
	{
		margin-left: 375px;
	}
	.main-nav.site-4 li.dropdown ul.dropdown-menu.dropdown-menu-leasing-guides-no-scroll.show, .main-nav.site-4 .dropdown-toggle-checkbox:checked ~ .dropdown-menu-leasing-guides-no-scroll
	{
		margin-left: 271px;
	}
}
/***************** Header and Nav *************************/