/*************************** Home **************************************/

.gcsiclsuia
{
	height: calc(100vh - 223px);
	overflow: scroll;
}
body.homepage
{
	background-color: #fff;
}

.find-make-model-homepage
{
	background-color: #06486a;
	background-image: url(/images/search-menu-car-bg.png);
	background-repeat: no-repeat;
}

.find-make-model-homepage .find-make-model-header
{
	font-size: 1.5rem;
	line-height: 1.2;
}
#home-car-tabs-block.fixed-bottom-until-md
{
	border-top-left-radius: 0.875rem;
	border-top-right-radius: 0.875rem;
}
.home-car-tabs
{
	/*width: 320px;*/
	/*color: $secondary;*/
	color: #fff;
	margin: 0px auto;
}
.home-car-tabs a
{
	/*color: $secondary;*/
	color: #69b7e0;
	font-size: 0.75rem;
}
.home-car-tabs a:hover, .home-car-tabs a:focus, .home-car-tabs a.active, .home-car-tabs a.active:hover, .home-car-tabs a.active:focus
{
	color: #fff;
}
#home-car-tabs-block.fixed-bottom-until-md .home-car-tabs a:hover, #home-car-tabs-block.fixed-bottom-until-md .home-car-tabs a:focus, #home-car-tabs-block.fixed-bottom-until-md .home-car-tabs a.active,
#home-car-tabs-block.fixed-bottom-until-md .home-car-tabs a.active:hover, #home-car-tabs-block.fixed-bottom-until-md .home-car-tabs a.active:focus
{
	color: #69b7e0;
}
#home-cars-section
{
	position: relative;
	z-index: 25;
}
@media screen and (min-width: 576px)
{	
	.find-make-model-homepage .find-make-model
	{
		max-width: 420px;
	}
	.find-make-model-homepage .find-make-model-form
	{
		padding-left: 7.5rem;
	}
	.find-make-model-homepage #find-make-model-img
	{
		left: -3rem;
		top: -7rem;
	}
	.home-car-tabs
	{
		/*width: 430px;*/
	}
}

@media screen and (min-width: 768px)
{
	.gcsiclsuia
	{
		height: auto;
		overflow: visible;
	}
	.find-make-model-homepage .find-make-model
	{
		max-width: 460px;
	}
	.find-make-model-homepage .find-make-model-header
	{
		font-size: 2rem;
	}
	.find-make-model-homepage #find-make-model-img {
		left: -5rem;
	}
}

@media screen and (min-width: 992px)
{
	.find-make-model-homepage .find-make-model
	{
		margin-right: auto;
	}
	.find-make-model-homepage .find-make-model-header
	{
		font-size: 2rem;
	}
}

/*************************** Home **************************************/