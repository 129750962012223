/*********************** Other Websites Section *********************/

@media screen and (min-width: 768px)
{
	.col-leasecarlogo, .col-leasevanlogo, .col-leasepickuplogo, .col-centralcontractslogo, .col-leaseelectriclogo
	{
		line-height: 60px;
	}
}
@media screen and (min-width: 992px)
{
	.col-leasecarlogo, .col-leasevanlogo, .col-leasepickuplogo, .col-centralcontractslogo, .col-leaseelectriclogo
	{
		line-height: 71px;
	}
}

/*********************** Other Websites Section *********************/