/***************** General *******************************/

/* custom scrollbar */
/*::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #06486D;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}*/

.sticky-top
{
	top: -1px;
}
.full-page
{
	height: 100%;
}
.full-page-main-wrap
{
	height: 100%;
	display: flex;
	flex-direction: column;
}
.full-page-main
{
	flex: 1;
	overflow: auto;
}
.text-icon-1
{
	color: #a0b9c6;
}
/****************  text-black doesn't appear to work so added here ******************/
.text-black
{
	color: #000;
}
.text-gray-500
{
	color: #adb5bd;
}
a
{
	outline: none;
}
h1 b, h2 b, .h1 b, .h2 b
{
	font-weight: inherit;
	color: #3089b7;
}
small.smaller
{
	font-size: 0.75rem;
}
.btn-xl
{
	padding: 1rem 2rem;
}
.border-radius-xl
{
	border-radius: 1.5rem;
}
.btn-success, .btn-success:hover, .btn-success:active, .btn-success:focus
{
	color: #fff;
}
.blue-vertical-gradient-overlay
{
	background-image: linear-gradient(to bottom, rgba(6,72,106,1), rgba(6,72,106,0));
	height: 100px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
}
.blue-vertical-gradient-overlay-bottom
{
	background-image: linear-gradient(to top, rgba(0,115,174,1), rgba(0,115,174,0));
	height: 100px;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 10;
}
.shade-overlay
{
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.25);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}
/****************************** $alt-font ******************************/
.alt-font
{
	font-family: arial;
}
.alt-font-imp
{
	font-family: arial !important;
}
.line-spacer
{
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid #d4d4d4;
}
.car-types-img
{
	min-height: 49px;
	display: flex;
    align-items: flex-end;
}
a.is-disabled
{
	pointer-events: none;
}
.emulate-link
{
	cursor: pointer;
}
.body-colour, a.body-colour:focus, a.body-colour:hover
{
	/* color: $body-color; */
	color: #575757;
}
.btn-success.disabled, .btn-success:disabled
{
	color: #fff;
}
.table-no-border>tbody>tr>td,.table-no-border>tbody>tr>th,.table-no-border>tfoot>tr>td,.table-no-border>tfoot>tr>th,.table-no-border>thead>tr>td,.table-no-border>thead>tr>th
{
	border-bottom: 0px none;
}
td.label-cell {
	font-weight: bold;
	width: 1%;
}
.bg-light-grey
{
	/*background-color: $light-grey;*/
	background-color: #f9f9f9;
}
.table-striped > tbody > tr:nth-of-type(2n+1)
{
	/*background-color: $light-grey;*/
	background-color: #f9f9f9;
}
ul.footer-links li a, ul.footer-links li a:hover, ul.footer-links li a:focus, a.footer-link, a.footer-link:hover, a.footer-link:focus, a.link-white, a.link-white:hover, a.link-white:focus
{
	color: #fff;
	outline: none;
}
ul.footer-links li a, ul.footer-links li a:hover, ul.footer-links li a:focus, a.footer-link, a.footer-link:hover, a.footer-link:focus
{
	font-size: 1.125rem;
}
ul.footer-links-w-dash li a::before
{
	content: "\2013\a0\a0\a0";
}
.cc-family-footer
{
	position: relative
}
.cc-family-footer a
{
	/*color: $info;*/
	color: #69b7e0;
	position: absolute;
	top: 36px;
	left: 136px;
	font-size: 0.875rem;
}
.cc-family-footer a:hover, .cc-family-footer a:focus
{
	/*color: $info;*/
	color: #69b7e0;
}
.footer-tel
{
	background-image: url(/images/tel-circled.png);
	background-repeat: no-repeat;
	padding-left: 56px;
	margin-top: 1.5rem;
}
.footer-address
{
	background-image: url(/images/marker-circled.png);
	background-repeat: no-repeat;
	padding-left: 56px;
	margin-top: 1.5rem;
}
#footer-supplier-logos-mob
{
	margin-left: -36px;
	margin-right: -36px;
	padding-top: 1rem;
	padding-bottom: 3rem;
}
#footer-supplier-logos-desk
{
	padding-top: 1rem;
	padding-bottom: 2rem;
}
#modal-business-personal .modal-body
{
	/* background-color: $dark; */
	background-color: #06486a;
	border: 2px solid #fff;
	border-radius: 6px;
}
.modal-business-personal-close
{
	position: absolute;
	background-color: transparent;
	border: 0px none;
	top: 0.5rem;
	right: 0.5rem;
}
.gradient-reverse
{
	background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.15)) !important;
}
.car-box-border
{
	border: 1px solid #06486a;
	margin-top: 1.5rem;
}
.car-box
{
	border: 0px none;
	border-radius: 0px;
	border-bottom: 1px solid #fff;
}
.car-box-img-wrap
{
	position: relative;
}
.car-box .card-img, .car-box .card-img-top
{
	border-radius: 0px;
}
.car-box-gradient
{
	position: absolute;
	z-index: 30;
	top: 0;
	left: 0;
	width: 100%;
	height: 3.5rem;
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.car-box-title
{
	color: #fff;
	font-size: 1.5rem;
	position: absolute;
	z-index: 30;
	top: 0.5rem;
	left: 0px;
	padding-left: 0.625rem;
}
.car-box .ratio div
{
	overflow: hidden;
}
.car-box-price
{
	padding: 0.5rem;
	background-color: #fff;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
	position: absolute;
	bottom: 0;
	right: 0.5rem;
}
.car-box .card-text
{
	font-size: 0.75rem;
}
.popover-header
{
	/* font-family: $alt-font; */
	font-family: Arial;
	/* background-color: $dark; */
	background-color: #06486a;
	color: #fff;
	border: 1px solid #fff;
	font-size: 1rem;
}
.popover-body
{
	/* font-family: $alt-font; */
	font-family: Arial;
	font-size: 0.875rem;
}
.regular-page
{
	/*font-family: $alt-font;*/
	font-family: Arial;
}
.regular-page h1, .regular-page .h1
{
	/*color: $text-primary;*/
	color: #0073ae;
}
.regular-page h2, .regular-page .h2, .regular-page h3, .regular-page .h3
{
	/*color: $success*/
	color: #548A3E;
}

.regular-car-page, .regular-car-page h1, .regular-car-page h2, .regular-car-page h3
{
	color: #fff;
}
.regular-car-page a
{
	color: #69b7e0;
}
.regular-car-page a:hover, .regular-car-page a:active, .regular-car-page a:focus
{
	color: #7dd1fd;
}

.regular-page h1, .regular-page .h1,
.regular-page h2, .regular-page .h2,
.regular-page h3, .regular-page .h3,
.regular-page h4, .regular-page .h4,
.regular-page h5, .regular-page .h5,
.regular-page h6, .regular-page .h6
{
	/* font-family: $font-family-base; */
	font-family: "Arial Rounded", Arial;
}

.regular-page header
{
	margin-bottom: 0px;
}

.fixed-top-under-nav
{
	position: fixed;
    top: 44px;
    right: 0;
    left: 0;
    z-index: 1010;
}

.btn:disabled
{
	cursor: not-allowed;
	pointer-events: all !important;
}

.hidden
{
	display: none;
}

ul.tnc-list {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

ul.tnc-list li {
	margin: 0px;
	margin-left: 24px;
	background-image: none;
	padding-left: 0px;
}

ul.tnc-list li table {
	width: 100%;
}

ul.tnc-list li table tr td {
	vertical-align: top;
}

ul.tnc-list li table tr td.bullet,.keep-font h3 span.bullet {
	width: 24px;
}

.clamp-text {
  --lh: 1.5rem;
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}
.clamp-text::before {
  position: absolute;
  content: "\25bc";
  inset-block-end: 0; /* "bottom" */
  inset-inline-end: 0; /* "right" */
  font-size: 0.875rem;
  bottom: 0px;
  /*content: "\2026";
  font-weight: bold;*/
}
.clamp-text::after {
  content: "";
  position: absolute;
  inset-inline-end: 0; /* "right" */
  width: 1rem;
  height: 1rem;
  background: white;
}
.clamp-text-link
{
	cursor: pointer;
}
.clamp-text-link-show
{
	cursor: default;
}
.clamp-text-link-show .clamp-text
{
	max-height: none;
}
.clamp-text-link-show .clamp-text::before
{
	content: "";
}
.clamp-text-lines-1
{
	max-height: calc(var(--lh) * 1);
}
.clamp-text-lines-2
{
	max-height: calc(var(--lh) * 2);
}
.clamp-text-lines-3
{
	max-height: calc(var(--lh) * 3);
}
.clamp-text-lines-4
{
	max-height: calc(var(--lh) * 4);
}
.clamp-text-lines-5
{
	max-height: calc(var(--lh) * 5);
}
.clamp-text-lines-6
{
	max-height: calc(var(--lh) * 6);
}
.clamp-text-lines-7
{
	max-height: calc(var(--lh) * 7);
}
.clamp-text-lines-8
{
	max-height: calc(var(--lh) * 8);
}
.clamp-text-lines-9
{
	max-height: calc(var(--lh) * 9);
}
.clamp-text-lines-10
{
	max-height: calc(var(--lh) * 10);
}
.truncate-show
{
	display: block;
}
.truncate-hide
{
	display: none;
}
.form-check-input-X2 {
	height: 2rem;
	width: 2rem;
}
.mxw-100 {
	max-width: 100px;
}
.mxw-240 {
	max-width: 240px;
}
.border-bottom-0-imp {
	border-bottom: 0px !important;
}
.border-top-0-imp {
	border-top: 0px !important;
}

@media screen and (min-width: 350px)
{
	.d-350-none
	{
		display: none;
	}
}
@media screen and (min-width: 576px)
{
	.truncate-show
	{
		display: none;
	}
	.truncate-hide
	{
		display: block;
	}
	.car-box .card-body
	{
		min-height: 136px;
	}
	.car-list-wrapper-makes .car-box .card-body
	{
		min-height: 90px;
	}
	.car-box-row .col:nth-child(2n-1) .car-box
	{
		border-right: 1px solid #fff;
		margin-left: -1px;
	}
}
@media screen and (min-width: 588px)
{
	#footer-supplier-logos-mob
	{
		margin-left: calc((((100vw - 588px) / 2) + 36px) * -1);
		margin-right: calc((((100vw - 588px) / 2) + 36px) * -1);
	}
}
@media screen and (min-width: 768px)
{
	.table-no-top-border-sm tr:first-child td
	{
		border-top: 0px none;
	}

	.blue-horizontal-gradient-overlay
	{
		background-image: url(/images/blue-horizontal-gradient.png);
		background-repeat: repeat-y;
		background-size: contain;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
	#footer-supplier-logos-mob
	{
		margin-left: calc((((100vw - 768px) / 2) + 36px) * -1);	
		margin-right: calc((((100vw - 768px) / 2) + 36px) * -1);
	}
	td.label-cell {
		white-space: nowrap !important;
	}
}
@media screen and (min-width: 992px) and (max-width: 1199px)
{
	.cc-family-footer img
	{
		width: 120px;
		height: auto;
	}
	.cc-family-footer a
	{
		top: 40px;
		left: 90px;
	}
}
@media screen and (min-width: 992px)
{
	.car-box-row .col:nth-child(2n-1) .car-box
	{
		border-right: 0px none;
		margin-left: 0px;
	}
	.car-box-row .col:nth-child(3n-1) .car-box
	{
		border-right: 1px solid #fff;
		margin-left: -1px;
	}
	.car-box-row .col:nth-child(3n-2) .car-box
	{
		border-right: 2px solid #fff;
		margin-left: -2px;
	}
	
	.clamp-text-lines-lg-0
	{
		max-height: none;
	}
	.clamp-text-lines-lg-1
	{
		max-height: calc(var(--lh) * 1);
	}
	.clamp-text-lines-lg-2
	{
		max-height: calc(var(--lh) * 2);
	}
	.clamp-text-lines-lg-3
	{
		max-height: calc(var(--lh) * 3);
	}
	.clamp-text-lines-lg-4
	{
		max-height: calc(var(--lh) * 4);
	}
	.clamp-text-lines-lg-5
	{
		max-height: calc(var(--lh) * 5);
	}
	.clamp-text-lines-lg-6
	{
		max-height: calc(var(--lh) * 6);
	}
	.clamp-text-lines-lg-7
	{
		max-height: calc(var(--lh) * 7);
	}
	.clamp-text-lines-lg-8
	{
		max-height: calc(var(--lh) * 8);
	}
	.clamp-text-lines-lg-9
	{
		max-height: calc(var(--lh) * 9);
	}
	.clamp-text-lines-lg-10
	{
		max-height: calc(var(--lh) * 10);
	}
}
@media screen and (min-width: 1200px)
{
	.car-box-row .col:nth-child(3n-1) .car-box,
	.car-box-row .col:nth-child(3n-2) .car-box
	{
		border-right: 0px none;
		margin-left: 0px;
	}
	.car-box-row .col:nth-child(4n-3) .car-box
	{
		border-right: 3px solid #fff;
		margin-left: -3px;
	}
	.car-box-row .col:nth-child(4n-2) .car-box
	{
		border-right: 2px solid #fff;
		margin-left: -2px;
	}
	.car-box-row .col:nth-child(4n-1) .car-box
	{
		border-right: 1px solid #fff;
		margin-left: -1px;
	}
}
@media screen and (max-width: 767px)
{
	.fixed-bottom-until-md
	{
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1010;
	}
}
@media screen and (max-width: 575px) {
	.border-bottom-0-xs-only-imp {
		border-bottom: 0px !important;
	}
	.border-top-0-xs-only-imp {
		border-top: 0px !important;
	}
}
@media screen and (max-width: 374px)
{
	.fs-u375-1-125
	{
		font-size: 1.125rem !important;
	}
}
@media screen and (max-width: 349px)
{
	.fs-u350-1
	{
		font-size: 1rem !important;
	}
	.ps-u350-1-1
	{
		padding-left: 0.25rem;
	}
	.pe-u350-1-1
	{
		padding-right: 0.25rem;
	}
	.hidden-u350
	{
		display: none !important;
	}
	.cc-family-footer a
	{
		top: 60px;
		left: 56px;
	}
}
@media screen and (max-width: 339px)
{
	.hidden-u340
	{
		display: none !important;
	}
}

/***************** General *******************************/