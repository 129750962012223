/*******************************  Ribbons *******************************/

td.special-highlight, td.stock-highlight
{
	padding: 0px;
	width: 26px;
	vertical-align: middle;
	/*background-color: $danger;*/
	background-color: #ed1f1f;
}
td.stock-highlight
{
	/*background-color: $success;*/
	background-color: #548a3e;
}
td.special-highlight > div, td.stock-highlight > div
{
	position: relative;
}
td.special-highlight > div > div, td.stock-highlight > div > div
{
	transform: rotate(270deg);
	-webkit-transform: rotate(270deg); /* Needed for Safari */
	/*font-family: $alt-font;*/
	font-family: arial;
	color: #fff;
	position: absolute;
	left: -7px;
	bottom: -9px;
}
td.stock-highlight > div > div
{
   left: -17px;
   bottom: -9px;
}

div.special-highlight, div.stock-highlight
{
	padding: 0px;
	vertical-align: middle;
	width: 100px;
	height: 100px;
	vertical-align: top;
	background-color: transparent;
	position: absolute;
	bottom: 0px;
	left: 0px;
}
div.special-highlight > div, div.stock-highlight > div
{
	position: relative;
}
div.special-highlight > div > div, div.stock-highlight > div > div
{
	/*font-family: $alt-font;*/
	font-family: arial;
	color: #fff;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
	overflow: hidden;
	width: 100px; height: 100px;
	text-align: right;
}

div.special-highlight > div > div > span, div.stock-highlight > div > div > span
{
	text-align: center;
	line-height: 24px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	width: 140px;
	display: block;
	position: absolute;
	top: 48px;
	left: -30px;
	/*background-color: $danger;*/
	background-color: #ed1f1f;
}
div.stock-highlight > div > div > span
{
	/*background-color: $success;*/
	background-color: #548a3e;
}

@media screen and (max-width: 767px)
{
	td.special-highlight + td, td.stock-highlight + td
	{
	   /*padding-left: 18px !important;*/
	}
}

@media screen and (min-width: 768px)
{
	td.special-highlight, td.stock-highlight
	{
        width: 70px;
		height: 70px;
        vertical-align: top;
		background-color: transparent;
    }
    td.special-highlight > div > div, td.stock-highlight > div > div
	{
        position: absolute;
		left: 0px;
		top: 0px;
		bottom: auto;
		z-index: 1;
		overflow: hidden;
		width: 77px; height: 77px;
		text-align: right;
		transform: none;
		-webkit-transform: none;
    }
    td.special-highlight > div > div > span, td.stock-highlight > div > div > span
	{
		text-align: center;
		line-height: 24px;
		transform: rotate(-60deg);
		-webkit-transform: rotate(-60deg);
		width: 120px;
		display: block;
		position: absolute;
		top: 25px;
		left: -23px;
		/*background-color: $danger;*/
		background-color: #ed1f1f;
    }
	td.stock-highlight > div > div > span
	{
		/*background-color: $success;*/
		background-color: #548a3e;
	}
}

/*******************************  Ribbons *******************************/