/***************************   Shortcuts   *****************************/
.fs-1-all
{
	/*font-size: 2.1875rem; /* 35 */
	font-size: 2.5rem; /* 40 */
}
.fs-2-all
{
	/*font-size: 1.75rem; /* 28 */
	font-size: 2rem; /* 32 */
}
.fs-3-all
{
	/*font-size: 1.53125rem; /* 24.5 */
	font-size: 1.75rem; /* 28 */
}
.fs-4-all
{
	/*font-size: 1.3125rem; /* 21 */
	font-size: 1.5rem; /* 24 */
}
.fs-5-all
{
	/*font-size: 1.09375rem; /* 17.5 */
	font-size: 1.25rem; /* 20 */
}
.fs-6-all
{
	/*font-size: 0.875rem; /* 14 */
	font-size: 1rem; /* 16 */
}
.display-1-all
{
	font-size: 5rem /* 80 */
}
.display-2-all
{
	font-size: 4.5rem /* 72 */
}
.display-3-all
{
	font-size: 4rem /* 64 */
}
.display-4-all
{
	font-size: 3.5rem /* 56 */
}
.display-5-all
{
	font-size: 3rem /* 48 */
}
.display-6-all
{
	font-size: 2.5rem /* 40 */
}

.lh-1
{
	line-height: 1;
}
.w1
{
	width: 1px;
}
.fs-1rem
{
	font-size: 1rem;
}
.fs-0-875
{
	font-size: 0.875rem;
}
.fs-1-875
{
	font-size: 1.875rem /* 30 */
}
.fs-2-25
{
	font-size: 2.25rem /* 36 */
}

.nh300-imp
{
	min-height: 300px !important;
}


@media screen and (min-width: 576px)
{
	.fs-sm-1-1
	{
		font-size: 1.1rem;
	}
}
@media screen and (min-width: 768px)
{
	.fs-md-1-2
	{
		font-size: 1.2rem;
	}
}
@media screen and (min-width: 992px)
{
	.fs-lg-1-0
	{
		font-size: 1rem;
	}
	.fs-lg-1-125
	{
		font-size: 1.125rem;
	}
	.fs-lg-1-5
	{
		font-size: 1.5rem;
	}
	.f2-lg-2-0
	{
		font-size: 2rem;
	}
}
@media screen and (min-width: 1200px)
{
	.fs-xl-1-3
	{
		font-size: 1.3rem;
	}
}
@media screen and (min-width: 1400px)
{
	.fs-xxl-1-4
	{
		font-size: 1.4rem;
	}
}

@media screen and (max-width: 359px)
{
	.fs-0-875-max-359
	{
		font-size: 0.875rem;
	}
}

/***************************   Shortcuts   *****************************/