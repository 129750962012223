$alt-font: Arial;
$font-family-base: "Arial Rounded", Arial;
//$font-size-base: 0.875rem;
$link-decoration: none;
$link-hover-decoration: none;
$border-radius: 1rem;
$border-radius-sm: 0.75rem;
$border-radius-lg: 1.25rem;
$green:   #548a3e;
$primary: #0073ae;
$secondary: #3089b7;
$info: #69b7e0;
$danger: #ed1f1f;
$light: #e2f5fe;
$dark: #06486a;
$body-bg: $dark;
$body-color: #575757;
$light-grey: #f9f9f9;
$component-active-color: $primary;
$component-active-bg: $light;
$form-check-input-checked-border-color: $info;
$input-btn-focus-color-opacity: .25;
$input-btn-focus-color: rgba($info, $input-btn-focus-color-opacity);
$table-striped-bg: #f9f9f9;
// 3089b7 lease in h1 - secondary
// 69b7e0 special msg back & arrow 3 - info
// 2a7da8 arrow 2 - use secondary
// e2f5fe (switch) checkbox / slider knob background - light
// a7e3ff (switch) checkbox / slider knob border
// 7cbddf key features
// 7dd1fd options
// success: 548a3e
// danger: ed1f1f
$form-range-track-height: 0.0625rem;
$form-range-track-bg: $primary;
$form-range-track-border-radius: 0px;
$form-range-thumb-width: 2.5rem;
$form-range-thumb-border-radius: 2.5rem;
$form-range-thumb-border: 1px solid $info;
$enable-negative-margins: true;
$aspect-ratios: (
  "1x1": 100%,
  "3x2": calc(2 / 3 * 100%),
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

$thumbnail-bg: #ffffff;
$form-range-thumb-bg: #f9f9f9;

// success gradient 548a3e to 446f32
// hover 6e9c5b to 5c824b

// Start Bootstrap

// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "nav";
@import "card";
@import "modal";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

// End Bootstrap

.link-color {
  color: $link-color !important;

  &:hover {
    color: $link-hover-color !important;
  }
}

// Bigger Form Switches

@mixin switch($res: 'sm') {
    $index: 1rem;
    $mainVal: 1rem;
  
    @if $res == 'md' {
        $index: 2rem;
        $mainVal: 1.5rem;
    } @else if $res == 'lg' {
        $index: 3rem;
        $mainVal: 2rem;
    } @else if $res == 'xl' {
        $index: 4rem;
        $mainVal: 2.5rem;
    }

    .form-check-input {
        height: $mainVal;
        width: calc(#{$index} + 0.75rem);
        border-radius: $mainVal * 2;
    }
}

.form-check-input {
  clear: left;
}

// YOU CAN PUT ALL RESOLUTION HERE
// sm - DEFAULT, md, lg, xl

.form-switch.form-switch-sm {
    @include switch();
}

.form-switch.form-switch-md {
    @include switch('md');
}

.form-switch.form-switch-lg {
    @include switch('lg');
}

.form-switch.form-switch-xl {
    @include switch('xl');
}

// Bigger Form Switches

.form-switch.form-switch-dual-on .form-check-input
{
	background-image: escape-svg($form-switch-checked-bg-image);
	background-color: #f9f9f9;
	border-color: $info;
}

/*.form-switch .form-check-input:focus
{
    border: 1px solid rgba(0,0,0,.25);
	background-image: escape-svg($form-switch-bg-image);
}

.form-switch .form-check-input:checked:focus
{
    background-image: escape-svg($form-switch-checked-bg-image);
	background-color: $light;
	border-color: $info;
}*/

@font-face 
{
	font-family: 'Arial Rounded';
	src: url(/fonts/ARLRDBD.TTF);
	font-display: swap;
}

#top-nav a, #top-nav a .bi
{
	display: block;
}

#top-nav a div
{
	white-space: nowrap;
	font-size: 0.75rem;
	line-height: 1;
}

// Override Aspect ratios
$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
	"3x2": calc(2 / 3 * 100%)
);

@import "sections/general";
@import "sections/header-and-nav";
@import "sections/home";
@import "sections/other-websites-section";
@import "sections/ribbons";
@import "sections/shortcuts";
